<template>
  <section id="telegram-stickers" class="features_wrap light-gray  p-t-b-80">
    <b-container>
      <b-row>
        <b-col md="12" class="section-header mb-5 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">We have Telegram stickers</h2>

          <p>
            You can use our cool stickers created by our Bro <a href="https://www.instagram.com/somebody_cute">somebody_cute</a>
            <br/>
            Stickers pack can be found <strong><a href="https://t.me/addstickers/Speancletree">here</a></strong>
          </p>
        </b-col>


        <b-col
          md="12"
          lg="7"
          sm="12"
          class="mb-4 mb-lg-0"
        >
          <b-row>
            <b-col
              md="3"
              lg="3"
              sm="4"
              cols="4"
              class=" mb-4 animate__animated animate__slideInLeft"
              v-for="(feature, key) in features" :key='key'
            >
              <b-card class=" text-center">
                <img :src="feature.src" alt=""/>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <!-- end of col-6 -->
        <b-col
          md="6"
          offset-md="3"
          sm="6"
          offset-sm="3"
          lg="4"
          offset-lg="1"
          cols="6"
          offset="3"
          class="d-flex align-items-center animate__animated animate__slideInRight"
        >
          <img
            src="@/assets/home/telegramStickers/speancletreeTelegramLogo.png"
            class="img-responsive w-100"
            alt=""
          >
        </b-col>

      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          src: require('@/assets/home/telegramStickers/sticker_1.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_2.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_3.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_4.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_5.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_6.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_7.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_8.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_9.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_10.png')
        },
        {
          src: require('@/assets/home/telegramStickers/sticker_11.png')
        }
      ]
    };
  }
};
</script>

<style>
#telegram-stickers {
  padding: 40px 0;
}
</style>
