<template>
  <div class="main-header header-fixed-default" id="home-header">
    <b-navbar
      class="navbar container w-100 navbar-expand-lg navbar-transparent bg-transparent"
      toggleable="lg"
      type=""
      variant=""
    >
      <b-navbar-brand href="/">
        <div class="logo">
          <img src="@/assets/images/landing/lOGO_d/logo_white.png" alt=""/>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-collapse"
        class="eva eva-menu-outline text-18 text-white"
      />

      <b-collapse id="nav-collapse" is-nav>
        <div style="margin: auto"></div>

        <b-navbar-nav class="header-part-right">
          <li
            class="nav-item"
            v-for="(link, index) in links"
            :key="index"
          >
            <a :href="link.href" class="m-2" v-smooth-scroll>{{ link.text }}</a>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          href: '#our-games',
          text: 'Our Games'
        },
        {
          href: '#telegram-stickers',
          text: 'Telegram stickers'
        },
        {
          href: '#we-work-with',
          text: 'We develop for'
        },
        {
          href: '#blog',
          text: 'Blog'
        },
        {
          href: '#follow-us',
          text: 'Follow Us'
        }
      ]
    };
  },

  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if(scroll >= 80) {
        document.querySelector('.main-header')
          .classList
          .add('header-fixed');
      } else {
        document.querySelector('.main-header')
          .classList
          .remove('header-fixed');
      }
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style>
</style>
