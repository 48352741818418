<template>
  <div>
    <section id="intro-wrap" class="intro1Wrap  text-left text-white">
      <b-container>
        <b-row>
          <b-col md="6" class="intro1LeftSection pb-5 animate__animated animate__slideInLeft">
            <h1 class=" font-weight-bold text-white text-42 mb-3 t-shadow">
              SPEANCLETREE
            </h1>

            <p class=" text-20 mb-4 text-justify">
              We are a game development team.
              We might be young but we are full of ideas.
              We care about every user we have making the games interesting and pleasant to play.
              You can be sure we add our love as a special ingredient to every game we create.
            </p>

            <short-features/>
          </b-col>

          <b-col md="6" class=" intro1RightSection d-flex align-items-center animate__animated animate__slideInRight">
            <div class="intro1ProductImage offset-sm-3 offset-2">
              <img
                src="@/assets/home/headerGame.png"
                class="img-responsive"
                alt=""
              />
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div class="overlay"></div>
    </section>
  </div>
</template>

<script>
import shortFeatures from './header/shortFeatures';

export default {
  components: {
    shortFeatures
  }
};
</script>
