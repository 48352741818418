import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/games',
    component: () => import('./../views/Games.vue'),
    children: [
      {
        path: 'drop-neon-blocks',
        component: () => import('./../views/Games/DropNeonBlocks.vue')
      },
      {
        path: 'blockerio',
        component: () => import('./../views/Games/Blockerio.vue')
      }
    ]
  },

  {
    path: '/blog',
    component: () => import('./../views/Blog.vue'),
    children: []
  }
];

const router = new VueRouter({
  mode: 'history',
  // scrollBehavior(to) {
  //   if(to.hash) {
  //     return {
  //       selector: to.hash
  //     };
  //   }
  //   return { x: 0, y: 200 };
  // },
  base: process.env.BASE_URL,
  routes
});

export default router;
