<template>
  <div class="card text-left o-hidden mb-4 m-3">
    <router-link :to="game.link" class="link-padding">
      <img
        class="image-height"
        :src="game.src"
        alt="Card image cap"
      />
    </router-link>


    <div class="card-body">
      <h5 class="card-title m-0 font-weight-bold">
        {{ game.name }}

        <span class="text-muted">
          {{ comingSoonLabel }}
        </span>
      </h5>
    </div>

    <div class="card-footer pl-3">
      <router-link
        class="btn btn-lg p-1 mr-1 text-dark"
        :to="game.link"
      >
        <span class="eva eva-link-2-outline"></span>
      </router-link>

      <a
        v-if="game.appStoreLink !== ''"
        class="btn btn-lg p-1 mr-1 text-dark"
        :href="game.appStoreLink"
        target="_blank"
      >
        <span style="font-size: 24px" class="fa fa-apple pull-left"></span>
      </a>

      <a
        v-if="game.playStoreLink !== ''"
        class="btn btn-lg p-1 mr-1 text-dark"
        :href="game.playStoreLink"
        target="_blank"
      >
        <span style="font-size: 24px" class="fa fa-android pull-left"></span>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'game',

  components: {},

  computed: {
    comingSoonLabel() {
      return this.game.comingSoon ? '(Coming soon...)' : '';
    }
  },

  props: {
    game: Object
  }
};
</script>

<style scoped>
@media (max-width: 720px) {
  .link-padding {
    padding: 0 20%;
  }

  .image-height {
    max-height: 80vh;
  }
}

</style>
