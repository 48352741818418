<template>
  <b-col md="12" sm="4" cols="4" :class="styles">
    <div class="o-hidden p-0 text-center">
      <a :href="platform.link" target="_blank">
        <img
          class="img-responsive"
          :src="platform.path"
          alt=""
        />

        <p v-if="!platform.link" class="text-muted">
          Coming soon
        </p>
      </a>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'platform',

  props: {
    platform: Object,
    isLast: Boolean
  },

  computed: {
    styles() {
      if(this.isLast) {
        return 'mb-lg-0 mb-md-0 mb-sm-0';
      } else {
        return 'mb-4';
      }
    }
  }
};
</script>

<style scoped>

</style>
