<template>
  <b-col
    md="6"
    sm="12"
    class="mb-lg-0 mb-md-0 mb-4 animate__animated animate__slideInLeft"
  >
    <b-card class="text-left o-hidden">
      <div class="card_img_left w-40 float-left">
        <img class="card-img" :src="this.blogPost.bg_svg" alt="Card image"/>
      </div>

      <div class="card_right_content w-60 p-3 float-right">
        <div class="pt-3 pb-4">
          <h3 class="m-0 text-capitalize font-weight-bold">
            {{ blogPost.title }}
          </h3>

          <h5 class="font-weight-light text-uppercase">
            {{ blogPost.author }}
          </h5>
        </div>

        <div class="font-weight-light pb-3">
          <p>{{ blogPost.description }}</p>

          <a
            routerLink="/landing/blog-details"
            class="btn half-button btn-gradient text-white"
          >
            Read More
          </a>
        </div>
      </div>
    </b-card>
  </b-col>
</template>

<script>
export default {
  name: 'blogItem',

  props: {
    blogPost: Object
  },

  mounted() {
    console.log(this.blogPost);
  }
};
</script>

<style scoped>

</style>
