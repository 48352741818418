<template>
  <!-- start work carousel -->
  <section id="our-games" class="work-carousel-wrap ">
    <b-container>
      <b-row>
        <div class="section-header col-md-12 mb-5 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">Our Games</h2>
          <p>
            Here you can find our ready to play and ongoing game. Good luck and have fun.
          </p>
        </div>

        <b-col md="12">
          <div class="slick-work-carousel-wrap animate__animated animate__fadeIn animate__slower">
            <carousel
              :slideBy="3"
              :nav="false"
              :dots="false"
              :autoplay="false"
              :responsive="{
                1: { items: 1, nav: true, navText: false },
                768: { items: 2, nav: true, navText: false },
                1000: { items: 3, nav: true, navText: false }
              }"
            >
              <game
                v-for="(game, index) in games"
                :key="index"
                :game="game"
              />
            </carousel>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import carousel from 'vue-owl-carousel';
import game from './ourGames/game';

export default {
  components: { carousel, game },

  data() {
    return {
      games: [
        {
          src: require('@/assets/home/ourGames/DropNeonBlocks.jpg'),
          name: 'Drop neon blocks',
          desc: 'Nice puzzle game with power of neon colors',
          link: '/games/drop-neon-blocks',
          playStoreLink: 'https://play.google.com/store/apps/details?id=com.speancletree.dropneonblocks.puzzlegame',
          appStoreLink: '',
          comingSoon: false
        },
        {
          src: require('@/assets/home/comingSoon.jpg'),
          name: 'Blockerio',
          desc: 'Tap and destroy blocks with similar color and get score',
          link: '/games/blockerio',
          playStoreLink: '',
          appStoreLink: '',
          comingSoon: true
        }
      ]
    };
  }
};
</script>
