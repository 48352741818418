<template>
  <section id="blog" class="news-two-wrap light-gray p-t-b-80">
    <b-container>
      <b-row>
        <b-col md="12" class="section-header mb-5 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">Our Blog</h2>
          <p>
            Coming soon...
          </p>
        </b-col>
      </b-row>

      <b-row>
        <blog-post
          v-for="(blogPost, key) in blogPosts"
          :key="key"
          :blogPost="blogPost"
        />
      </b-row>
    </b-container>
  </section>
</template>

<script>
import blogPost from './blog/blogPost';

export default {
  components: {
    blogPost
  },

  data() {
    return {
      blogPosts: [
        // {
        //   bg_svg: require('@/assets/images/landing/news/news1.svg'),
        //   title: 'Beauty of Sunshine',
        //   author: 'Alex Doe',
        //   description:
        //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae reprehenderit, sequi quisquam repellendus corporis adipisci veniam! Mollitia suscipit excepturi laboriosam rem ut voluptas.'
        // },
        // {
        //   bg_svg: require('@/assets/images/landing/news/news2.svg'),
        //   title: 'Beauty of Sunshine',
        //   author: 'Alex Doe',
        //   description:
        //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae reprehenderit, sequi quisquam repellendus corporis adipisci veniam! Mollitia suscipit excepturi laboriosam rem ut voluptas.'
        // }
      ]
    };
  }
};
</script>

