import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueLand from './plugins/Vueland.kit';
import vueSmoothScroll from 'vue2-smooth-scroll';
import 'animate.css/animate.min.css';
import firebase from 'firebase/app';
import 'firebase/analytics';

// STAGING
// const firebaseConfig = {
//   apiKey: 'AIzaSyAZKadccR49O5tfeMiCPEfamIL91jBQFT8',
//   authDomain: 'speancletreehome.firebaseapp.com',
//   databaseURL: 'https://speancletreehome.firebaseio.com',
//   projectId: 'speancletreehome',
//   storageBucket: 'speancletreehome.appspot.com',
//   messagingSenderId: '767054541109',
//   appId: '1:767054541109:web:ab0a7be3d8fd35d6e5dc7c',
//   measurementId: 'G-3721NXY3S6'
// };

const firebaseConfig = {
  apiKey: "AIzaSyAZKadccR49O5tfeMiCPEfamIL91jBQFT8",
  authDomain: "speancletreehome.firebaseapp.com",
  databaseURL: "https://speancletreehome.firebaseio.com",
  projectId: "speancletreehome",
  storageBucket: "speancletreehome.appspot.com",
  messagingSenderId: "767054541109",
  appId: "1:767054541109:web:861d3b1df580fd0be5dc7c",
  measurementId: "G-1M5MC4EYV5"
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.use(vueSmoothScroll);
Vue.use(VueLand);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
