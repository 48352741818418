<template>
  <section id="we-work-with" class="left-image-wrap p-t-b-80">
    <b-container>
      <b-row>
        <b-col md="12" class=" section-header mb-5 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">We develop for</h2>

          <p>
            We create cross-platform games for you. You can find our games in such platforms as Android, iOS and Steam
          </p>
        </b-col>

        <b-col md="8" sm="12" class=" mb-5 mb-lg-0 mb-md-0 mt-mb-5 animate__animated animate__slideInLeft">
          <div class="left-image-carousel">
            <div>
              <carousel
                :autoplay="true"
                :dots="false"
                :nav="false"
                :responsive="{
                  0: { items: 1, nav: true, navText: false },
                  600: { items: 1, nav: true, navText: false },
                  992: { items: 2, nav: true, navText: false }
                }"
              >
                <div v-for="(image, key) in images" :key="key" class="text-center">
                  <img class="img-responsive mb-4" :src="image.path" alt=""/>
                </div>
              </carousel>
            </div>
          </div>
        </b-col>

        <b-col md="2" sm="12" class="offset-md-2 animate__animated animate__slideInRight">
          <b-row class="h-100">
            <platform
              v-for="(platform, index) in platforms"
              :key="index"
              :platform="platform"
              :is-last="index === platforms.length - 1"
            />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import carousel from 'vue-owl-carousel';
import platform from './platforms/platform';

export default {
  components: {
    carousel,
    platform
  },

  data() {
    return {
      platforms: [
        {
          path: require('@/assets/home/platforms/androidLogo.png'),
          link: 'https://play.google.com/store/apps/developer?id=Speancletree'
        },
        {
          path: require('@/assets/home/platforms/iosLogo.png')
        },
        {
          path: require('@/assets/home/platforms/steamLogo.png')
        }
      ],

      images: [
        {
          path: require('@/assets/home/platforms/combo.png')
        },
        {
          path: require('@/assets/home/platforms/hammer.png')
        },
        {
          path: require('@/assets/home/platforms/move.png')
        },
        {
          path: require('@/assets/home/platforms/blastline.png')
        }
      ]
    };
  }
};
</script>
<style>
#we-work-with {
  padding: 40px 0;
}
</style>
