<template>
  <section id="follow-us" class="services-wrap light-gray">
    <b-container>
      <b-row>
        <b-col md="12" class="section-header mb-2 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">Follow us</h2>
        </b-col>

        <b-col
          class="service-wrap mb-4 animate__animated animate__fadeIn animate__slower"
          md="3" lg="3" sm="4" cols="4"
          v-for="(socialNetwork, key) in socialNetworks"
          :key="key"
        >
          <a :href="socialNetwork.link">
            <img
              class="img-thumbnail shadow"
              :src="socialNetwork.src"
              :alt="socialNetwork.name"
            />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      socialNetworks: [
        {
          src: require('@/assets/home/followUs/facebookLogo.png'),
          name: 'Facebook',
          link: 'https://www.facebook.com/Speancletree-101934851650800'
        },
        {
          src: require('@/assets/home/followUs/twitterLogo.png'),
          name: 'Twitter',
          link: 'https://twitter.com/speancletree'
        },
        {
          src: require('@/assets/home/followUs/instaLogo.png'),
          name: 'Instagram',
          link: 'https://www.instagram.com/speancletree/?igshid=jn5jkaqasqzs'
        },
        {
          src: require('@/assets/home/followUs/youtubeLogo.png'),
          name: 'Youtube',
          link: 'https://www.youtube.com/channel/UCwT9CzDg77xHMPs3j1RJqlA'
        }
      ]
    };
  }
};
</script>

