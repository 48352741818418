<template>
  <b-col md="4" class="mb-lg-0 mb-md-0 animate__animated animate__slideInRight">
    <div class=" o-hidden text-left">
      <div class="">
        <h4 class="card-title font-weight-bold">Contact Info</h4>

        <!--                <div class="address-wrap mb-3">-->
        <!--                  <ul class="list-group list-unstyled">-->
        <!--                    <li class="">-->
        <!--                      <i class="eva eva-pin-outline mr-2"></i>UI-Lib ,United-->
        <!--                      Kingdom-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->

        <div class="address-wrap mb-3">
          <ul class="list-group list-unstyled">
            <li class="">
              <i class="eva eva-email-outline mr-2"></i>info@speancletree.com
            </li>
          </ul>
        </div>

        <!--                <div class="address-wrap mb-3">-->
        <!--                  <ul class="list-group list-unstyled">-->
        <!--                    <li class="">-->
        <!--                      <i class="eva eva-phone-call-outline mr-2"></i>-->
        <!--                      +8801961081993-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->

        <!--                <section class="social-wrap ">-->
        <!--                  <div class="social-btns-wrap">-->
        <!--                    <a-->
        <!--                      class="social-btn text-30 facebook ml-0" href="#"-->
        <!--                    ><i class="eva eva-facebook"></i-->
        <!--                    ></a>-->

        <!--                    <a-->
        <!--                      class="social-btn text-30 twitter" href="#"-->
        <!--                    ><i class="eva eva-twitter"></i-->
        <!--                    ></a>-->

        <!--                    <a-->
        <!--                      class="social-btn text-30 google" href="#"-->
        <!--                    ><i class="eva eva-google"></i-->
        <!--                    ></a>-->
        <!--                  </div>-->
        <!--                </section>-->
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'blockFour'
};
</script>

<style scoped>

</style>
