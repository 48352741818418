<template>
  <b-col md="4" class="mb-4">
    <div class=" o-hidden text-left">
      <div class=" ">
        <h4 class="card-title font-weight-bold">Other Pages</h4>

        <div class=" mb-5 lead">
          <ul class="list-inline">
            <li class=" mb-3 " v-for="(link, index) in links" :key="index">
              <router-link :to="link.href">{{ link.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'blockTwo',

  data() {
    return {
      links: [
        {
          href: '/',
          name: 'Home'
        },
        {
          href: '/blog',
          name: 'Our Blog(Coming soon)'
        },
        {
          href: '/games/drop-neon-blocks',
          name: 'Drop Neon Blocks'
        },
        {
          href: '/games/blockerio',
          name: 'Blockerio(Coming soon)'
        }
      ]
    };
  }
};
</script>

<style scoped>

</style>
