<template>
  <div class="">
    <dx-navbar/>

    <dx-header/>

    <dx-our-games/>

    <dx-telegramStickers/>

    <dx-platforms/>

    <!--    <dx-team/>-->

    <dx-blog/>

    <dx-follow-us/>

    <!--    <dx-contact/>-->

    <dx-footer/>
  </div>
</template>

<script>
import team from './../components/home/team';
import blog from './../components/home/blog';
import navbar from './../components/home/navbar';
import footer from './../components/shared/footer';
import header from './../components/home/header';
import followUs from './../components/home/followUs';
import contact from './../components/home/contact';
import ourGames from './../components/home/ourGames';
import platforms from './../components/home/platforms';
import telegramStickers from './../components/home/telegramStickers';

export default {
  metaInfo: {
    bodyAttrs: {
      class: ['landing-gradient-red-orange']
    }
  },

  components: {
    dxBlog: blog,
    dxTeam: team,
    dxFooter: footer,
    dxNavbar: navbar,
    dxHeader: header,
    dxContact: contact,
    dxFollowUs: followUs,
    dxOurGames: ourGames,
    dxPlatforms: platforms,
    dxTelegramStickers: telegramStickers
  }
};
</script>
