<template>
  <div>
    <div class="flex-grow-1"></div>

    <section class="footer_wrap" id="footer-wrap">
      <b-container>
        <b-row class="row">
          <block-one/>
          <block-two/>
          <block-four/>
        </b-row>

        <b-row>
          <b-col class="text-center">
            © 2020 Speancletree. All rights reserved
          </b-col>
        </b-row>
      </b-container>
    </section>

    <cookie-law
      theme="base"
      :message="cookiesMessage"
    />

  </div>
</template>

<script>
import blockOne from './footer/blockOne';
import blockTwo from './footer/blockTwo';
import blockFour from './footer/blockFour';
import CookieLaw from 'vue-cookie-law';

export default {
  components: {
    blockOne,
    blockTwo,
    blockFour,
    CookieLaw
  },

  data() {
    return {
      cookiesMessage: 'We uses cookies to ensure you get the best experience on our website'
    }
  }
};
</script>
