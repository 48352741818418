<template>
  <b-col md="4" class="mb-4 animate__animated animate__slideInLeft">
    <div class=" o-hidden text-left">
      <div class="">
        <h4 class="card-title font-weight-bold">Speancletree</h4>
        <p class="">
          We would appreciate your comments and feedback on our games!
          <br>
          <a href="mailto:feedback@speancletree.com">feedback@speancletree.com</a>
        </p>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'blockOne'
};
</script>

<style scoped>

</style>
